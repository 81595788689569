<template>
    <div>
        <el-card>
            <nav class="out">
                <nav class="input" style="width: 240px">
                    <el-input v-model="querifo.keyword" size="medium" placeholder="输入标题" />
                </nav>
                <label
                    style="
                        font-size: 14px;
                        float: left;
                        transform: translateY(9px);
                    "
                    >发布日期：</label
                >
                <nav class="input" style="width: 350px">
                    <el-date-picker
                        v-model="querifo.createTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        size="medium"
                    />
                </nav>
                <nav class="input" style="width: 200px;">
                    <el-select v-model="querifo.top" placeholder="推荐状态" size="medium">
                        <el-option label="全部" value="-1"> </el-option>
                        <el-option label="推荐" value="1"></el-option>
                        <el-option label="不推荐" value="0"></el-option>
                    </el-select>
                </nav>
                <nav class="input">
                    <el-button size="small" @click="reset">重置</el-button>
                    <el-button type="primary" size="small" @click="submit">{{ btn ? "搜索" : "loading.." }}</el-button>
                    <el-button
                        v-show="$store.state.btn_if.indexOf('tag_add') != -1"
                        type="primary"
                        size="small"
                        @click="new_add"
                        >新增专题</el-button
                    >
                </nav>
            </nav>
        </el-card>
        <!-- <el-button type="primary" class="btnAdd" @click="editdialogs = true"
      >新增专题</el-button
    > -->

        <el-card style="margin-top: 10px">
            <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
                <el-table-column prop="id" label="编号" />
                <el-table-column prop="name" label="标题" />
                <el-table-column prop="desc" label="描述" show-overflow-tooltip width="250" />
                <el-table-column prop="banner" label="图片">
                    <template slot-scope="{ row }">
                        <img :src="baseUrl + yu + row.banner" alt="" style="width: 150px" />
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="initViews" label="真实浏览量" /> -->
                <el-table-column prop="clickViews" label="浏览量" />
                <el-table-column prop="publishStatus" label="发布状态">
                    <template slot-scope="{ row }">
                        <el-tag type="warning" size="small" class="box">{{
                            row.publishStatus ? "发布" : "不发布"
                        }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="top" label="推荐状态">
                    <template slot-scope="{ row }">
                        <el-tag type="warning" size="small" class="box">{{ row.top ? "推荐" : "不推荐" }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="oderNum" label="排序">
                    <template #header>
                        <span>排序</span>
                        <span style="font-size: 12px"><br />（当前最大值：{{ orderMax }}）</span>
                        <el-tooltip effect="dark" placement="top">
                            <div slot="content">数值越大，显示越靠前</div>
                            <i class="el-icon-warning-outline" style="margin-left: 5px" />
                        </el-tooltip>
                    </template>
                    <template slot-scope="{ row }">
                        <Remark
                            :row="row"
                            :val="{
                                url: '/admin/Tag/editOrderNum',
                                pw: 'enterprise_cert_show',
                            }"
                            @getlist="getlsit"
                        />
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" sortable width="180" />
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                            v-show="$store.state.btn_if.indexOf('tag_list_edit') != -1"
                            type="text"
                            icon="el-icon-edit"
                            size="mini"
                            class="skyblue"
                            @click="showEdit(scope.row.id)"
                            >编辑</el-button
                        >
                        <el-button
                            v-show="$store.state.btn_if.indexOf('tag_list_delete') != -1"
                            type="text"
                            icon="el-icon-delete"
                            size="mini"
                            class="red"
                            @click="removeuserid(scope.row.id)"
                            >删除</el-button
                        >
                        <el-button
                            v-show="$store.state.btn_if.indexOf('tag_top') != -1"
                            type="text"
                            icon="el-icon-share"
                            size="mini"
                            style="color: tan"
                            @click="setTop(scope.row, scope.row.top == 0 ? 1 : 0)"
                            >{{ scope.row.top == 0 ? "推荐" : "不推荐" }}</el-button
                        >
                        <el-button
                            v-show="$store.state.btn_if.indexOf('tag_publish') != -1"
                            type="text"
                            icon="el-icon-s-promotion"
                            size="mini"
                            class="green"
                            @click="setPS(scope.row, scope.row.publishStatus == 0 ? 1 : 0)"
                            >{{ scope.row.publishStatus == 0 ? "发布" : "不发布" }}</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="querifo.pagenum"
                background
                :page-sizes="[1, 2, 5, 10]"
                :page-size="querifo.pagesize"
                layout="total, prev, pager, next"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </el-card>
        <el-dialog :title="editdialog ? '修改专题' : '新增专题'" :visible.sync="editdialogs" width="50%">
            <el-form ref="editFormRef" label-width="70px" :model="form">
                <el-form-item label="标题" prop="realname">
                    <el-input v-model="form.name" />
                </el-form-item>
                <el-form-item label="描述" prop="realname">
                    <el-input v-model="form.desc" />
                </el-form-item>

                <el-form-item label="图片" prop="banner">
                    <Img :banner="form.banner" :action="action" @event="setImg" />
                </el-form-item>
                <el-form-item label="分享图" prop="shareBanner">
                    <up-img
                        :banner.sync="form.shareBanner"
                        tip
                        :tip_text="false"
                        theCustom="请上传比例5:4的图片，否则会影响前台显示"
                        :action="action"
                    />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button
                    @click="
                        editdialogs = false;
                        form = {};
                    "
                    >取 消</el-button
                >
                <el-button v-show="!editdialog" type="primary" @click="add">{{
                    btn ? "确 定" : "loading.."
                }}</el-button>
                <el-button v-show="editdialog" type="primary" @click="edituserinfo">{{
                    btn ? "确 定" : "loading.."
                }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Img from "../../components/upImg/index.vue";
import Remark from "../../components/remark/orderNum.vue";
export default {
    components: {
        Img,
        Remark,
    },
    data() {
        return {
            querifo: {
                pagenum: 1,
                pagesize: 10,
                keyword: "",
                createTime: [],
            },
            action: {
                action: "tag",
            },
            btn: true,
            list: [],
            editform: {
                name: "",
                type: "",
                id: 0,
            },
            form: {
                name: "",
                desc: "",
                banner: "",
                shareBanner: "",
            },
            total: 0,
            editdialog: false,
            editdialogs: false,
            title: "",
            createtime: "",
            yu: process.env.VUE_APP_STATIC_DIR,
            baseUrl: this.$store.state.env.cos,
            orderMax: 0,
        };
    },
    created() {
        this.getlsit();
    },
    methods: {
        setImg(val) {
            this.form.banner = val;
        },
        async setPS(row, val) {
            const { data: res } = await this.$http.post("/admin/Tag/publish", {
                id: row.id,
                publishStatus: val,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlsit();
            }
        },
        async setTop(row, val) {
            const { data: res } = await this.$http.post("/admin/Tag/top", {
                id: row.id,
                top: val,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlsit();
            }
        },
        rowClass() {
            return "background:#F5F7FA;";
        },
        handleSizeChange(e) {
            // console.log(e)
            this.querifo.pagesize = e;
            this.getlsit();
        },
        handleCurrentChange(e) {
            // console.log(e)
            this.querifo.pagenum = e;
            this.getlsit();
        },
        reset() {
            this.title = "";
            this.createtime = "";
        },
        submit() {
            this.getlsit();
        },
        getlsit() {
            this.$http
                .get("/admin/Tag/getList", {
                    params: {
                        page: this.querifo.pagenum,
                        pageSize: this.querifo.pagesize,
                        keyword: this.querifo.keyword,
                        createTime: this.querifo.createTime.join("~"),
                        top: this.querifo.top ? this.querifo.top : -1,
                    },
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.list = res.data.list;
                        this.total = res.data.totalCount;
                        this.orderMax = res.data.orderMax;
                    }
                });
        },
        showEdit(id) {
            this.$http.get("/admin/Tag/getById?id=" + id).then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.editdialogs = true;
                    this.editdialog = true;
                    this.form = res.data;
                }
            });
        },
        new_add() {
            this.editdialogs = true;
            this.editdialog = false;
            this.form = {
                name: "",
                desc: "",
                banner: "",
            };
        },
        edituserinfo() {
            if (this.btn) {
                this.$http.post("/admin/Tag/edit", this.form).then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.$message.success(res.message);
                        this.editdialogs = false;
                        this.getlsit();
                    }
                    this.btn = true;
                });
            }
            this.btn = false;
        },
        removeuserid(id) {
            this.$confirm("此操作将永久删除, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$http.delete("/admin/Tag/del", { params: { id: id } }).then(({ data: res }) => {
                        if (res.errorCode == 200) {
                            this.$message.success(res.message);
                            if (this.total % 10 == 1) {
                                this.querifo.pagenum--;
                            }
                            this.getlsit();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        add() {
            if (this.btn) {
                this.$http.post("/admin/Tag/add", this.form).then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.$message.success(res.message);
                        location.reload();
                    }
                    this.btn = true;
                });
            }
            this.btn = false;
        },
    },
};
</script>

<style lang="less" scoped>
.out {
    // width: 500px;

    margin-bottom: 20px;

    .input {
        width: 37%;
        display: inline-block;
    }
}
.red {
    color: red;
}
.skyblue {
    color: skyblue;
}
.green {
    color: green;
}
.yellow {
    color: pink;
}
</style>
