<template>
  <div>
    <div class="input-box">
      <el-input
        v-show="key"
        v-model="row.orderNum"
        size="small"
        class="id"
        :autofocus="key"
        @blur="handleInputBlur(row)"
      />
    </div>
    <template v-if="!permissions">
      <span
        v-show="!key"
        @click="$store.state.btn_if.indexOf(val.pw) != -1 && showinput(row)"
      >
        {{ row.orderNum }}</span
      >
      <i
        v-show="!key"
        :class="$store.state.btn_if.indexOf(val.pw) != -1 && 'el-icon-edit'"
        @click="$store.state.btn_if.indexOf(val.pw) != -1 && showinput(row)"
      />
    </template>
    <template v-else>
      <span
        v-show="!key"
        @click="showinput(row)"
      >
        {{ row.orderNum }}</span
      >
      <i
        v-show="!key"
        :class="'el-icon-edit'"
        @click="showinput(row)"
      />
    </template>
  </div>
</template>

<script>
export default {
  //   props: ['row', 'val'],
  props: {
    row: {},
    val: {},
    permissions: false
  },
  data() {
    return {
      key: false
    }
  },
  methods: {
    handleInputBlur(row) {
      this.$http.post(this.val.url, row).then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.$message.success(res.message)
          this.$emit('getlist')
          this.key = false
        }
      })
    },
    showinput() {
      this.key = true
      setTimeout(() => {
        $('.id input').focus()
      }, 10)
    }
  }
}
</script>

<style lang="less" scoped></style>
